import React, { useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import logotopo from '../assets/images/logo/logotopotransparent.png';
import { MdBusiness, MdShoppingCart, MdTransferWithinAStation, MdPersonPin, MdInput, MdContactMail, MdExitToApp, MdThumbUp } from "react-icons/md";
import './NavMenu.css';
import '../custom.css';


export default function Header() {
    const existingTokens = JSON.parse(sessionStorage.getItem("tokens"));
    const [userName, setUserName] = useState("");
    const [isLoggedIn, setIsLoggedOn] = useState(false);

    useEffect(() => {
        if (existingTokens !== null && userName === "") {
            let name = existingTokens.firstName;
            setUserName(name);
            setIsLoggedOn(true);
        }
    }, [existingTokens, userName]);  // Dependency array for this useEffect

    const [isCollapsed, setCollapsed] = useState(true);

    function toggleNavbar() {
        setCollapsed(!isCollapsed);
    }


    return (
        <header className="stickyheader">
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 headerlayout" light>
                <NavbarBrand href="/">
                    <img src={logotopo} style={{ width: 150, marginTop: -10 }} alt="topologo"/>
                </NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" /> 
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isCollapsed} onClick={toggleNavbar} navbar>
                    <ul className="navbar-nav flex-grow">

                        <NavItem>
                            <NavLink tag={Link} className="text-primary" to="/productcategories"><MdBusiness />{" "}Producten</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-primary" to="/promotions"><MdThumbUp />{" "}Aanbiedingen</NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink tag={Link} className="text-primary" to="/about"><MdPersonPin />{" "}Over TOPO</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-primary" to="/cart"><MdShoppingCart />{" "}Bestellen</NavLink>
                        </NavItem>
                        {!isLoggedIn &&
                            <NavItem>
                                <NavLink tag={Link} className="text-primary" to="/login"><MdInput />{" "}Inloggen</NavLink>
                            </NavItem>
                        }
                        {isLoggedIn &&
                            <NavItem>
                                <NavLink tag={Link} className="text-primary" onClick={(e) => { sessionStorage.removeItem('portal'); }} to="/user"><MdContactMail />{" "}<b>{userName}</b></NavLink>
                            </NavItem>
                        }
                        {isLoggedIn &&
                            <NavItem>
                                <NavLink tag={Link} className="text-danger" to="/logout"><b><MdExitToApp /></b></NavLink>
                            </NavItem>
                        }
                        
                    </ul>  
                      
                </Collapse>
               
            </Navbar>
        </header>
    );
}


/*
-- Removed on request of TOPO 26 Sept 2024
                        <NavItem>
                            <NavLink tag={Link} className="text-primary" to="/services"><MdTransferWithinAStation />{" "}Diensten</NavLink>
                        </NavItem>

--
                            <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className="text-primary">
                                <MdBusiness />{" "}Producten
                            </DropdownToggle>
                            <DropdownMenu>
                              <Row >
                                <Col className="border-right" sm="12">
                                  {leftItems.map((item, index) => (
                                    <DropdownItem key={item.id} href={`/product/category/${item.name.toLowerCase()}`}>
                                        <img src={item.image} alt={item.name} width="30" height="30" />
                                        {item.name}
                                    </DropdownItem>
                                  ))}
                                </Col>
                                <Col xs="6">
                                  {rightItems.map((item, index) => (
                                    <DropdownItem key={item.id} href={`/product/category/${item.name.toLowerCase()}`}>
                                        <img src={item.image} alt={item.name} width="30" height="30" />
                                        {item.name}
                                    </DropdownItem>
                                  ))}
                                    <DropdownItem key={Categories.length+1} href={`/products`}>
                                        <img src={logotopo} alt="Alles" width="30" height="30" />Alles
                                    </DropdownItem>
                                </Col>
                              </Row>
                            </DropdownMenu>
                          </UncontrolledDropdown>

                        {isCollapsed && (
                            <NavItem>
                                <NavLink tag={Link} className="text-primary"  to="/products"><MdBusiness />{" "}Products</NavLink>
                            </NavItem>
                        )}




 <NavItem>
                            <NavLink tag={Link} className="text-primary" to="/cart">{(cartItmCnt > 0) ? <Badge pill color="primary"><MdShoppingCart />{cartItmCnt}</Badge> : <MdShoppingCart />}{" "}Bestellen</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-primary"  to="/products"><MdBusiness />{" "}Producten</NavLink>
                        </NavItem>

<NavItem>
                            <NavLink tag={Link} className="text-primary" onClick={toggleCategories}><MdBusiness />{" "}Producten</NavLink>
                        </NavItem>




                            <NavItem>
                                <NavLink tag={Link} className="text-primary" to="/renting"><MdStoreMallDirectory />{" "}Verhuur</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-primary" to="/occasions"><MdRefresh />{" "}Occasions</NavLink>
                            </NavItem>



*/